.main__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
    min-height: 0;
    column-gap: 16px;
}

.main__file-viewer {
    flex-grow: 1;
}

.main__invoice-editor {
    overflow: auto;
    width: 100%;
    height: 100%;
}
