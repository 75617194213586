
.attachments-view__overlay {
  position: fixed;
  inset: 0;
  z-index: 2000;
  background-color: white;
}

.attachments-view__content {
  position: absolute;
  inset: 0;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

.attachments-view__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  outline: none;
}

.attachments-view__header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  flex-shrink: 0;
  column-gap: 8px;
  padding: 8px;
}

.attachments-view__file-details {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 100px;
  color: black;
}

.attachments-view__file-size {
  flex-shrink: 0;
}

.attachments-view__header-button {
  margin: 0 4px;
  flex-shrink: 0;
}

.attachments-view__icon {
  fill: black;
}

.attachments-view__header-icon:last-child {
  margin-right: 0;
}

.attachments-view__header-divider {
  flex-shrink: 0;
  fill: grey;
  fill-opacity: 1;
  margin: 0 12px;
}

.attachments-view__doc-viewer-container {
  flex-grow: 1;
  overflow-x: auto;
}

.attachments-view__doc-viewer {
  background: none !important;
}

.attachments-view__doc-viewer #image-renderer {
  background: none !important;
}

.attachments-view__doc-viewer #pdf-renderer {
  background: darkgrey !important;
  margin: 0;
}

.attachments-view__doc-viewer #txt-renderer {
  background: white !important;
  margin: 0;
}

.attachments-view__doc-viewer #html-body {
  padding: 0;
  margin: 0;
}

.attachments-view__doc-viewer #pdf-page-info {
  color: white;
}

.attachments-view__doc-viewer #html-renderer {
  padding: 0;
  background: white;
}

.attachments-view__doc-viewer #html-body {
  border: none;
}

.attachments-view__doc-viewer #proxy-renderer > div > table {
  padding: 0;
  background: white;
}

#pdf-controls {
  display: none;
}

.attachments-view__message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.attachments-view__message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.attachments-view__message-file-name {
  margin-top: 20px;
}

.attachments-view__message-text {
  margin-top: 8px;
}

.attachments-view__message-button-wrapper {
  margin-top: 12px;
}

.attachments-view__main-container {
  flex-grow: 1;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.attachments-view__navigation-button {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 32px;
  align-self: center;
}

.file-type-icon__container {
  height: 0;
}

