input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.panel-resize-handle {
  width: 16px;
  display: flex;
  align-items: center;
}

.panel-resize-handle__icon {
  width: 100%!important;
  margin: auto;
}

.rotate-90-deg {
  transform: rotate(90deg);
}

body {
  background-color: #f5f5f5;
}

.accounting-panel {
  background-color: white;
}